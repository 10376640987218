import React, { useCallback, useEffect, useState, useRef } from 'react'
import { lsGet, lsSet } from './Cloak/storage'
import Ui from './Cloak/Ui'
import VisualizerSetterContext from './contexts/VisualizerSetterContext'
import defaultTheme from './defaultTheme'

function getDefaultTheme() {
    return {
        ...defaultTheme,
    }
}

function applyAll(instance, theme) {
    Object.keys(theme).forEach((key) => {
        instance.set(key, theme[key])
    })
}

const showUi = typeof window !== 'undefined' && window.location.hash === '#ui'

export default function ThemeProvider({ children, ui = showUi }) {
    const [instance, setInstance] = useState()

    const [theme, setTheme] = useState(
        ui
            ? Object.assign(getDefaultTheme(), JSON.parse(lsGet('params', '{}')))
            : getDefaultTheme(),
    )

    const onUpdate = useCallback(
        (key, value) => {
            setTheme((c) => ({
                ...c,
                [key]: value,
            }))

            instance.set(key, value)
        },
        [instance],
    )

    const onReset = useCallback(() => {
        setTheme(defaultTheme)

        applyAll(instance, defaultTheme)
    }, [instance])

    const themeRef = useRef(defaultTheme)

    useEffect(() => {
        themeRef.current = theme
        lsSet('params', JSON.stringify(theme))
    }, [theme])

    useEffect(() => {
        if (!instance) {
            return
        }

        const { current: t } = themeRef

        applyAll(instance, t)
    }, [instance])

    useEffect(() => {
        if (!ui) {
            // No UI? We don't wanna react to `paste` events. Meybe we should move the "paste" logic
            // into the actual `Ui` component.
            return () => {}
        }

        function onPaste(e) {
            try {
                const data = JSON.parse(e.clipboardData.getData('text/plain'))

                if (!data || typeof data !== 'object') {
                    return
                }

                const newParams = {
                    ...themeRef.current,
                    ...data,
                }

                setTheme(newParams)

                applyAll(instance, newParams)
            } catch (err) {
                //
            }
        }

        window.addEventListener('paste', onPaste)

        return () => {
            window.removeEventListener('paste', onPaste)
        }
    }, [instance, ui])

    return (
        <VisualizerSetterContext.Provider value={setInstance}>
            {ui && <Ui params={theme} onUpdate={onUpdate} onResetClick={onReset} />}
            {children}
        </VisualizerSetterContext.Provider>
    )
}
