import { Vector3 } from 'three'
import { LATI_TRANS, LONG_TRANS } from '~shared/NetworkVisualizer'
import { Y_POS } from '~shared/NetworkVisualizer/Particles'

export async function getFeed() {
    const neighbors = await getNeighbors()

    const topologies = {}

    const nodeTopologies = {}

    const uniquenessGate = {}

    for (const { streamPartId, nodeId1: n1, nodeId2: n2 } of neighbors) {
        const defaultTopology = {
            streamPartId,
            connections: [],
            nodes: {},
        }

        const { [streamPartId]: topology = defaultTopology } = topologies

        topology.nodes[n1] = true

        topology.nodes[n2] = true

        const key = [streamPartId, ...[n1, n2].sort()].join('-')

        if (uniquenessGate[key]) {
            continue
        }

        uniquenessGate[key] = true

        topology.connections.push([n1, n2])

        topologies[streamPartId] = topology

        const size = Object.keys(topology.nodes).length

        const { [n1]: t1 = { nodes: {} }, [n2]: t2 = { nodes: {} } } = nodeTopologies

        if (t1 !== topology && size > Object.keys(t1.nodes).length) {
            nodeTopologies[n1] = topology
        }

        if (t2 !== topology && size > Object.keys(t2.nodes).length) {
            nodeTopologies[n2] = topology
        }
    }

    const coordinates = []

    const metadata = new WeakMap()

    const nodeCoordinates = {}

    let cursor = '0'

    const pageSize = 1000

    while (cursor != null) {
        const resp = await fetch('https://stream-metrics.streamr.network/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `
                    query GetNodes($pageSize: Int!, $cursor: String!) {
                        nodes(pageSize: $pageSize, cursor: $cursor) {
                            cursor
                            items {
                                id
                                location {
                                    country
                                    latitude
                                    longitude
                                }
                            }
                        }
                    }
                `,
                variables: { cursor, pageSize },
            }),
        })

        const {
            data: { nodes },
        } = await resp.json()

        cursor = nodes.cursor

        for (const item of nodes.items) {
            const { id: nodeId, location } = item

            if (!location) {
                continue
            }

            const { country, longitude, latitude } = location

            const v3 = new Vector3(longitude * LONG_TRANS, Y_POS, -latitude * LATI_TRANS)

            const hit = coordinates.find((v) => v3.distanceToSquared(v) < 0.0005)

            const { [nodeId]: topology = { streamPartId: null, connections: [], nodes: {} } } =
                nodeTopologies

            const topologySize = Object.keys(topology.nodes).length

            if (!hit) {
                coordinates.push(v3)

                metadata.set(v3, {
                    connections: topology.connections,
                    country,
                    latitude,
                    longitude,
                    nodeId,
                    streamPartId: topology.streamPartId,
                    topologySize: topologySize,
                })
            }

            nodeCoordinates[nodeId] = hit || v3

            if (hit) {
                /**
                 * Compare topology sizes associated with leading and current nodes
                 * and adjust metadata. We want the *top tier* node to lead the pack.
                 *
                 * Truthy `hit` means metadata exists. No need for extra checks.
                 */

                const { topologySize: currentTopologySize, ...current } = metadata.get(hit)

                if (currentTopologySize < topologySize) {
                    metadata.set(hit, {
                        ...current,
                        connections: topology.connections,
                        nodeId,
                        streamPartId: topology.streamPartId,
                        topologySize,
                    })
                }
            }
        }
    }

    return {
        coordinates,
        metadata,
        nodeCoordinates,
    }
}

async function getNeighbors() {
    let cursor = '0'

    const neighbors = []

    while (cursor) {
        const resp = await fetch('https://stream-metrics.streamr.network/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `
                    query GetNeighbors(
                        $cursor: String
                    ) {
                        neighbors(
                            cursor: $cursor
                            pageSize: 1000
                        ) {
                            items {
                                streamPartId
                                nodeId1
                                nodeId2
                            }
                            cursor
                        }
                    }
                `,
                variables: { cursor },
            }),
        })

        const {
            data: { neighbors: result },
        } = await resp.json()

        neighbors.push(...result.items)

        cursor = result.cursor
    }

    return neighbors
}
